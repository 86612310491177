<template>
  <div class="mm-god-mode-header">
    <div>
      <span class="mm-god-mode-header__description">
        Просмотр личного кабинета аккаунта:
      </span>
      <span class="mm-god-mode-header__user-info">
        {{ userName }}
      </span>
      <span
        class="mm-god-mode-header__logout"
        @click="onLogout"
      >
        Выйти
      </span>
    </div>

    <div class="mm-god-mode-header__description">
      Администратор {{ mpAdminInfoEmail }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useUserStore } from 'store/user.store';
import { storeToRefs } from 'pinia';
import { AuthHelper } from 'utils/authHelper.util';
import { getFullNameAbbreviation } from 'shared/utils/fullNameAbbreviation.util';

const userStore = useUserStore();

const { userInfo, mpAdminInfoEmail } = storeToRefs(userStore);

const userName = computed(() => `${getFullNameAbbreviation(userInfo?.value?.lastName, userInfo?.value?.name, userInfo?.value?.patronymic)} ${userInfo.value?.lastName && userInfo.value?.email ? userInfo.value.email : ''}`);

async function onLogout() {
  await AuthHelper.fetch('/api/auth/deleteCookie', { method: 'DELETE' });
  useUserStore()?.setUserLogout();
  location.reload();
}
</script>

<style lang="scss" scoped>
.mm-god-mode-header {
  height: 32px;
  background-color: #214237;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;

  &__user-info {
    color: white;
  }

  &__description {
    color: white;
    opacity: .6;
  }

  &__logout {
    color: #08A553;
    cursor: pointer;
    padding-left: 7px;
  }
}
</style>
